import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import 'rxjs/add/operator/do';

import { identifierModuleUrl } from '@angular/compiler';

import { Grupo } from '../../model/cadastro/grupo.model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class GrupoService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');
      if (param.length > 0){
        return this.http.get<any>( `${GERENCIAMENTO}/api/CadastroGrupo/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${GERENCIAMENTO}/api/CadastroGrupo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/CadastroGrupo/PorId/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/CadastroGrupo/Remover/${id}`);
    }

    salvar(grupo: Grupo): Observable<any>{
      if (grupo.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/CadastroGrupo/Alterar/${grupo.Id}`, grupo);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/CadastroGrupo/Inserir/`, grupo);
      }

    }

    uploadLogoIntranet(arquivo: any, id: number): Observable<any>{

      const url = `${GERENCIAMENTO}/api/empresa/logointranet/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }

    uploadLogoInternet(arquivo: any, id: number): Observable<any>{

      const url = `${GERENCIAMENTO}/api/empresa/logointernet/${id}/`;
      const headers = new HttpHeaders().append('noloader', 'noloader');

      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }
  }
